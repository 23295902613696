import { cn } from "../../lib/utils";

const AnimatedShinyText = ({
    children,
    className,
    shimmerWidth = 100,
}) => {
    const shimmerStyle = {
        "--shimmer-width": `${shimmerWidth}px`,
    };

    return (
        <p
            style={shimmerStyle}
            className={cn(
                "mx-auto max-w-md text-neutral-600/50 dark:text-neutral-400/50",
                "animate-shimmer bg-clip-text bg-no-repeat [background-size:var(--shimmer-width)_100%]",
                "bg-gradient-to-r from-transparent via-black/80 to-transparent dark:via-white/80",
                "relative overflow-hidden",
                className
            )}
        >
            {children}
        </p>
    );
};

export default AnimatedShinyText;

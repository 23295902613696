import React, { useState, useEffect } from 'react';
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "./components/ui/tabs"
import { Button } from "./components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
    DropdownMenuGroup,
    DropdownMenuSeparator,
    DropdownMenuItem,
    DropdownMenuLabel
} from "./components/ui/dropdown-menu";
import {
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from "./components/ui/tooltip"
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "./components/ui/card"
import { Input } from "./components/ui/input";
import { Settings } from 'lucide-react';
import axios from 'axios';
import { Megaphone } from 'lucide-react';
import { LogOut } from 'lucide-react';
import { Undo2 } from 'lucide-react';
import { RotateCcw } from 'lucide-react';
import { Wrench } from 'lucide-react';
import { TooltipProvider } from '@radix-ui/react-tooltip';
import { GoogleLogin } from '@react-oauth/google';
import ErrPopup from './components/zelfgemaakt/ErrPopup';
import { cn } from "./lib/utils";
import TextShimmer from "./components/magicui/animated-shiny-text";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import { Pen, Pencil } from 'lucide-react';
import { Check } from 'lucide-react';
import { Menu } from 'lucide-react';
import { EllipsisVertical } from 'lucide-react';
import { Paperclip } from 'lucide-react';
import { SquarePen } from 'lucide-react';
import { Map } from 'lucide-react';
import { Palette } from 'lucide-react';
import { X } from 'lucide-react';
import { CornerDownRight } from 'lucide-react';
import { SquareArrowOutUpRight } from 'lucide-react';
import ApexCharts from 'apexcharts'
import ReactApexChart from 'react-apexcharts';
import { parse, format, setDate, set } from 'date-fns';
import { nl } from 'date-fns/locale';
import { Helmet } from 'react-helmet';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "./components/ui/table"
import { Checkbox } from "./components/ui/checkbox"


const ApexChart = ({ sessies, setSessies, setTab }) => {
    const [selection, setSelection] = useState('one_month');

    const convertToTimestamp = (dateString) => {
        const datePart = dateString.split(', ')[1];
        let parsedDate = parse(datePart, 'dd-MM-yyyy', new Date(), { locale: nl });

        parsedDate.setDate(parsedDate.getDate() + 1); // Add one day
        return parsedDate.getTime();
    };

    const convertTimestampToDate = (timestamp) => {
        const date = new Date(timestamp);

        // Subtract one day
        date.setDate(date.getDate() - 1);

        const formattedDate = format(date, 'dd-MM-yyyy', { locale: nl });
        return formattedDate;
    };

    let currentSessies = sessies.filter(sessie => sessie.switch === "on");
    let filteredSessies = currentSessies.filter(sessie => sessie.nakijken === 0).reverse();

    const groupedData = filteredSessies.reduce((acc, sessie) => {
        const datePart = sessie.datum.split(', ')[1];
        if (!acc[datePart]) {
            acc[datePart] = { totalScore: 0, totalMax: 0, totalGem: 0, count: 0 };
        }
        acc[datePart].totalScore += sessie.score;
        acc[datePart].totalMax += sessie.max;
        acc[datePart].totalGem += sessie.gem;
        acc[datePart].count += 1;
        return acc;
    }, {});

    let minDate = filteredSessies[0].datum
    if (minDate) {
        minDate = convertToTimestamp(minDate);
    } else {
        minDate = new Date().getTime();
    }

    const series = [
        {
            name: 'Jouw',
            data: Object.keys(groupedData).map(datePart => {
                const { totalScore, totalMax } = groupedData[datePart];
                const averageScore = parseInt(totalScore / totalMax * 100);
                return [convertToTimestamp(`, ${datePart}`), averageScore];
            })
        },
        {
            name: 'Gem',
            data: Object.keys(groupedData).map(datePart => {
                const { totalGem, totalMax } = groupedData[datePart];
                const averageGem = parseInt(totalGem / totalMax * 100);
                return [convertToTimestamp(`, ${datePart}`), averageGem];
            })
        }
    ];

    const options = {
        chart: {
            id: 'area-datetime',
            type: 'area',
            height: 450,
            zoom: {
                autoScaleYaxis: true
            },
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: false, // Disable default download icon
                    pan: false,
                    zoom: false,
                    zoomin: '<div class=" w-[24px] h-[24px] cursor-pointer text-gray-600 hover:text-black"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="white" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-plus"><path d="M5 12h14"/><path d="M12 5v14"/></svg></div>',
                    zoomout: '<div class=" w-[24px] h-[24px] cursor-pointer text-gray-600 hover:text-black"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="white" stroke="currentColor" stroke-width="2" stroke-linecap="round" class="lucide lucide-minus"><path d="M5 12h14"/></svg></div>',
                    reset: '<div class=" w-[24px] h-[24px] cursor-pointer text-gray-600 hover:text-black"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-rotate-ccw"><path d="M3 12a9 9 0 1 0 9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" fill="white"/><path d="M3 3v5h5" fill="white"/></svg></div>'
                }
            },
            events: {
                markerClick: function (event, chartContext, { seriesIndex, dataPointIndex, w }) {
                    var seriesData = w.config.series[seriesIndex].data;
                    var clickedData = seriesData[dataPointIndex];

                    var convertedDate = convertTimestampToDate(clickedData[0]);
                    setDate(convertedDate);

                    setSessies(sessies.map(sessie => {
                        if (!sessie.datum.includes(convertedDate)) {
                            return { ...sessie, switch: "off" };
                        }
                        return sessie;
                    }));

                    setTab("lijst")
                }
            }
        },
        markers: {
            size: 5,  // Set the size of the markers
            strokeColors: '#fff',
            strokeWidth: 1,
            hover: {
                size: 6
            }
        },
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            enabled: true,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 100]
            }
        },
        labels: {
            style: {
                fontSize: '14px',
            },
        },
        legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            fontSize: '14px',
            fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif',
            fontWeight: 500,
            fontColor: '#0F172A',
            inverseOrder: false,
            customLegendItems: [],
            offsetX: 0,
            labels: {
                colors: ['#0F172A', '#0F172A'],
            },
            itemMargin: {
                horizontal: 5,
                vertical: 10
            },
            onItemClick: {
                toggleDataSeries: true
            },
            onItemHover: {
                highlightDataSeries: true
            },
            formatter: function (val, opts) {
                return val + " score (%)"
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: [],
                    fontSize: '14px',
                },
            },
            title: {
                style: {
                    fontSize: '14px',
                    fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif',
                },
            },
        },
        xaxis: {
            labels: {
                style: {
                    colors: [],
                    fontSize: '14px',
                },
            },
            title: {
                style: {
                    fontSize: '14px',
                    fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif',
                },
            },
            tooltip: {
                enabled: false
            },
            type: 'datetime',
            min: minDate,
            max: new Date().getTime() - 24 * 60 * 60 * 1000,
            tickAmount: 6,
        }
    };

    const updateData = (timeline) => {
        setSelection(timeline);
        const today = new Date();

        switch (timeline) {
            case 'one_month':
                const oneMonthAgo = new Date();
                oneMonthAgo.setMonth(today.getMonth() - 1);
                ApexCharts.exec('area-datetime', 'zoomX', oneMonthAgo.getTime(), today.getTime());
                break;
            case 'six_months':
                const sixMonthsAgo = new Date();
                sixMonthsAgo.setMonth(today.getMonth() - 6);
                ApexCharts.exec('area-datetime', 'zoomX', sixMonthsAgo.getTime(), today.getTime());
                break;
            case 'all':
                ApexCharts.exec('area-datetime', 'zoomX', convertToTimestamp(sessies.filter(sessie => sessie.switch === "on")[0].datum), today.getTime());
                break;
            default:
                break;
        }
    };

    return (
        <div>
            <div className="toolbar -mb-4">
                <Button variant="link" id="one_month" onClick={() => updateData('one_month')} className={selection === 'one_month' ? 'active' : ''}>1M</Button>
                <Button variant="link" id="six_months" onClick={() => updateData('six_months')} className={selection === 'six_months' ? 'active' : ''}>6M</Button>
                <Button variant="link" id="all" onClick={() => updateData('all')} className={selection === 'all' ? 'active' : ''}>ALL</Button>
            </div>
            <ReactApexChart id="apexchart" className="text-muted-foreground text-sm" options={options} series={series} type="area" height={450} />
        </div>
    );
};

function renderButtonText(paramValue, optiesValue) {
    if (paramValue) {
        return capitalizeFirstLetter(paramValue);
    } else if (Array.isArray(optiesValue) && optiesValue.length === 1) {
        return capitalizeFirstLetter(optiesValue[0]);
    } else if (typeof optiesValue === 'string') {
        return capitalizeFirstLetter(optiesValue);
    } else if (Array.isArray(optiesValue) && optiesValue.length === 0) {
        return 'Geen opties';
    } else {
        return 'Geen voorkeur';
    }
}

function capitalizeFirstLetter(string) {
    if (typeof string === 'string') {
        return string.charAt(0).toUpperCase() + string.slice(1);
    } return string
}

const App = () => {
    const [startPagina, setPagina] = useState(false);
    const [login, setLogin] = useState(false);

    const [res, setRes] = useState(false);
    const [examens, setExamens] = useState([]); //most important vars
    const [sessies, setSessies] = useState([]);

    const [params, setParams] = useState({}); //all options/keys
    const [selectedDate, setDate] = useState("")
    const [opties, setOpties] = useState({});
    const alleKeys = ['niveau', 'vak', 'onderwerp', 'moeilijkheidsgraad', 'jaar', 'tijdvak'];

    const [gekozenOpgave, setOpgave] = useState({}); //selection of opgave
    const [opgaveKey, setOpgaveKey] = useState("opg1");

    const [eMsg, setE] = useState(""); //error

    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [openSessies, setOpenSessies] = useState([]);

    const [naam, setNaam] = useState("");
    const [vulScore, setVulScore] = useState(false);
    const [activeTab, setTab] = useState("lijst");
    const [showTab, setShowTab] = useState(true);
    const [timeoutId, setTimeoutId] = useState(null);

    const [changeBox, setChangeBox] = useState(false); //checkboxes to change 
    const [changeOpgaves, setChangeOpgaves] = useState([]);

    // check all options based on params
    const createOpties = (tmpExamens) => {
        let currentExamens = tmpExamens.filter(examen => examen.switch === "on");
        let tmpOpties = {};
        for (const key of alleKeys) {
            tmpOpties[key] = [];
            currentExamens.forEach(examen => {
                if (examen[key] !== null) {
                    if (key === "onderwerp") {
                        let onderwerpKeys = examen[key].split("#");
                        onderwerpKeys.forEach(onderwerpKey => {
                            if (!tmpOpties[key].includes(onderwerpKey)) {
                                tmpOpties[key].push(onderwerpKey);
                            }
                        });
                    } else {
                        if (!tmpOpties[key].includes(String(examen[key]))) {
                            tmpOpties[key].push(String(examen[key]));
                        }
                    }
                }
            });
            tmpOpties[key] = tmpOpties[key].sort();
        }
        return {
            filteredOpties: {
                ...tmpOpties,
                jaar: [...tmpOpties.jaar].reverse(),
                niveau: (() => {
                    let vwoIndex = tmpOpties.niveau.indexOf("vwo");
                    if (vwoIndex !== -1) {
                        tmpOpties.niveau.splice(vwoIndex, 1);
                        tmpOpties.niveau.unshift('vwo');
                    }
                    return tmpOpties.niveau;
                })()
            }
        }
    }

    useEffect(() => {
        const initializeData = async () => {
            const response = await axios.get('/api/data', { withCredentials: true });
            if (response.error) {
                setE(response.error);
                return;
            }
            if (response.data.naam) {
                setNaam(response.data.naam);
            }
            setExamens(response.data.examens);
            setSessies(response.data.sessies)

            let tempOpties = createOpties(response.data.examens).filteredOpties;

            //check url params
            let tempParams = {}
            const searchParams = new URLSearchParams(window.location.search);
            const opgave = searchParams.get('opgave');
            if (opgave) {
                setOpgave({ 'id': opgave });
            }
            alleKeys.forEach(key => {
                //if search param in tempOpties
                if (searchParams.get(key) && tempOpties[key].includes(String(searchParams.get(key)))) {
                    tempParams[key] = String(searchParams.get(key));
                }
            });
            setParams(tempParams);
            setRes(true)
        };
        initializeData();
    }, [login]);

    useEffect(() => {
        if (res) {
            // filtering of examens based on params and set switch to on
            const paramData = (tempParms) => {
                let tmpExamens = examens.map((examen) => ({ ...examen, switch: "on" }));
                let tmpSessies = sessies.map((sessie) => ({ ...sessie, switch: "on" }));

                for (const key in tempParms) {
                    if (tempParms[key]) {
                        if (key === "onderwerp") {
                            tmpExamens = tmpExamens.map((examen) => {
                                if (examen.switch === "on" && examen[key] && examen[key].includes(tempParms[key])) {
                                    return { ...examen, switch: "on" };
                                }
                                return { ...examen, switch: "off" };
                            });
                            tmpSessies = tmpSessies.map((sessie) => {
                                if (sessie.switch === "on" && sessie[key] && sessie[key].includes(tempParms[key])) {
                                    return { ...sessie, switch: "on" };
                                }
                                return { ...sessie, switch: "off" };
                            });
                        } else {
                            tmpExamens = tmpExamens.map((examen) => {
                                if (examen.switch === "on" && examen[key] && examen[key].toString() === tempParms[key].toString()) {
                                    return { ...examen, switch: "on" };
                                }
                                return { ...examen, switch: "off" };
                            });
                            tmpSessies = tmpSessies.map((sessie) => {
                                if (sessie.switch === "on" && sessie[key] && sessie[key].toString() === tempParms[key].toString()) {
                                    return { ...sessie, switch: "on" };
                                }
                                return { ...sessie, switch: "off" };
                            });
                        }
                    }
                }
                return { filteredExamens: tmpExamens, filteredSessies: tmpSessies };
            }

            // set response of paramData
            let { filteredExamens, filteredSessies } = paramData(params);
            setExamens(filteredExamens);
            setSessies(filteredSessies);

            // check for selected date
            if (selectedDate) {
                let tmpSessies = sessies;
                tmpSessies = tmpSessies.map((sessie) => {
                    if (sessie.datum.includes(selectedDate)) {
                        return { ...sessie, switch: "on" };
                    }
                    return { ...sessie, switch: "off" };
                });
                setSessies(tmpSessies);
            }

            let { filteredOpties } = createOpties(filteredExamens);
            setOpties(filteredOpties);

            // check if param is necessary
            let necessaryParms = { ...params };
            for (const key in necessaryParms) {
                let tempParms = { ...necessaryParms, [key]: null };
                let { filteredExamens, filteredSessies } = paramData(tempParms);
                let { filteredOpties } = createOpties(filteredExamens);
                if (filteredOpties[key].length === 1) {
                    necessaryParms[key] = null;
                }
            }
            // update url
            let newUrl = "";
            for (let param in necessaryParms) {
                if (necessaryParms[param]) {
                    if (newUrl === "") {
                        newUrl += "/?";
                    }
                    newUrl += `${param}=${necessaryParms[param]}&`;
                }
            }
            if (Object.keys(gekozenOpgave).length !== 0) {
                if (Object.keys(gekozenOpgave).length === 1) {
                    let newOpgave = examens.find(examen => examen.id == gekozenOpgave.id);
                    if (newOpgave) {
                        setOpgave(newOpgave);
                    }
                }
                if (newUrl === "") {
                    newUrl += `/?opgave=${gekozenOpgave.id}`;
                } else if (newUrl.endsWith('&')) {
                    newUrl += `opgave=${gekozenOpgave.id}`;
                }
            } else {
                newUrl = newUrl.slice(0, -1);
            }
            let oldUrl = window.location.href.replaceAll("%20", " ")
            if (oldUrl.endsWith('/')) {
                oldUrl = oldUrl.slice(0, -1)
            }
            newUrl = window.location.origin + newUrl
            if (oldUrl !== newUrl) {
                window.history.pushState({}, '', newUrl);
                setParams(necessaryParms);
            }
        }
    }, [params, selectedDate, res]);

    const handleDropdownItemClick = (key, value) => {
        setParams(prevParams => {

            let newParams = { ...prevParams };

            if (prevParams[key]) {
                newParams[key] = null;

                if (key === 'onderwerp') {
                    newParams['vak'] = opties['vak'][0];
                }
            } else {
                newParams[key] = value;
            }
            return newParams;
        });
    };

    const onSuccess = async (response) => {
        const tokenId = response.credential;

        try {
            const res = await fetch('/inloggen', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ tokenId }),
                credentials: 'include'
            });
            const data = await res.json();
            if (data.error) {
                setE(data.error);
            } else {
                setLogin(true)
            }

        } catch (error) {
            console.error('Error during authentication:', error);
            setE(error.message);
        }
    };


    const icons = {
        "Maken": {
            "opg1": <Pencil className="2xl:w-9 2xl:h-9" />,
            "bij1": <Paperclip className="2xl:w-9 2xl:h-9" />,
            "uit": <SquarePen className="2xl:w-9 2xl:h-9" />,
            "kk": <Map className="2xl:w-9 2xl:h-9" />,
            "k": <Palette className="2xl:w-9 2xl:h-9" />,
        },
        "Antwo.": {
            "cv1": <Check className="2xl:w-9 2xl:h-9" />,
            "videos": <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-youtube 2xl:w-9 2xl:h-9"><path d="M2.5 17a24.12 24.12 0 0 1 0-10 2 2 0 0 1 1.4-1.4 49.56 49.56 0 0 1 16.2 0A2 2 0 0 1 21.5 7a24.12 24.12 0 0 1 0 10 2 2 0 0 1-1.4 1.4 49.55 49.55 0 0 1-16.2 0A2 2 0 0 1 2.5 17" /><path d="m10 15 5-3-5-3z" /></svg>,
        },
        "Errata": {
            "opg2": <Pencil className="2xl:w-9 2xl:h-9" />,
            "opg3": <Pencil className="2xl:w-9 2xl:h-9" />,
            "bij2": <Paperclip className="2xl:w-9 2xl:h-9" />,
            "cv2": <Check className="2xl:w-9 2xl:h-9" />,
            "cv3": <Check className="2xl:w-9 2xl:h-9" />,
        }
    }

    function createSendParms(alleKeys, opties) {
        let sendParams = {};
        alleKeys.map(key => {
            if (key != "moeilijkheidsgraad") {
                if (opties[key].length === 1) {
                    sendParams[key] = opties[key][0];
                } else if (params[key]) {
                    sendParams[key] = params[key];
                }
                else {
                    sendParams[key] = null;
                }
            }
        })
        return sendParams;
    }

    return (
        <>
            {Object.keys(gekozenOpgave).length > 1 ? (
                <Helmet>
                    <title>
                        {gekozenOpgave.titel.replace(/@@/g, ' ').replace(/QQ/g, '?')}
                    </title>
                    <meta
                        name="description"
                        content={`${gekozenOpgave.max}p examenopgave over ${gekozenOpgave.vak.toLowerCase()} ${gekozenOpgave.onderwerp ? gekozenOpgave.onderwerp.toLowerCase().replace(/##/g, ', ') : ''}`}
                    />
                </Helmet>
            ) : (Object.keys(params).length > 0 && Object.values(params).some(value => value !== null) ? (
                <Helmet>
                    <title>
                        {(() => {
                            let titel = "";
                            Object.keys(params).forEach((key) => {
                                if (params[key]) {
                                    if (titel === "") {
                                        if (typeof params[key] === 'number') {
                                            titel = params[key];
                                        } else {
                                            titel = params[key].charAt(0).toUpperCase() + params[key].slice(1).toLowerCase();
                                        }
                                    } else {
                                        titel += ', ' + String(params[key]).toLowerCase();
                                    }
                                }
                            });
                            return titel;
                        })()}
                    </title>
                    <meta
                        name="description"
                        content={`Vind hier ${examens.filter(examen => examen.switch === 'on').length} opgaves over ${Object.keys(params).map((key) => params[key] ? String(params[key]).toLowerCase() : '').filter(Boolean).join(', ')}`}
                    />
                </Helmet>
            ) : (
                <Helmet>
                    <title>Eindexamen.space</title>
                    <meta
                        name="description"
                        content="Lanceer je (eindexamen)cijfers de hoogte in!"
                    />
                </Helmet>
            ))}

            < div className="box-border w-[100vw] h-[100vh] block md:flex">
                {sidebarOpen && (
                    <div
                        id="linkerkant"
                        className="overflow-y-auto md:h-[100vh] w-[100vw] mb-10 md:mb-0 px-6 md:w-[50vw] lg:w-[40vw] xl:w-[35vw] rounded-bl-xl rounded-br-xl md:rounded-none lg:border-r bg-[#FBFBFB] border-[#E4E4E7]"
                    >
                        <Tabs defaultValue="voorkeuren">
                            <div className="box-border pt-6 pb-2 flex">
                                <TabsList
                                    className="mb-4">
                                    <TabsTrigger value="voorkeuren" onClick={() => setShowTab(true)}>
                                        <Settings size="20" />
                                    </TabsTrigger>
                                    {['Maken', 'Nakijken', 'Nagekeken'].map((status) => (
                                        <TabsTrigger
                                            key={status}
                                            value={status}
                                            className={`${window.innerWidth < 400 ? 'mx-0 px-1' : null}`}
                                            onClick={() => {
                                                setChangeOpgaves([]);
                                                setChangeBox(false);
                                                setShowTab(true);
                                            }}
                                            {...(examens && examens.filter(examen => examen.status === status && examen.switch === "on").length === 0 ? { disabled: true } : {})}
                                        >
                                            {status}(
                                            {examens.filter(examen => examen.switch === "on").length > 0
                                                ? examens.filter((examen) => examen.status === status && examen.switch === "on").length
                                                : '∞'}
                                            )
                                        </TabsTrigger>
                                    ))}
                                </TabsList>
                            </div>
                            <TabsContent value="voorkeuren" className="flex-col justify-center">
                                {showTab && alleKeys.map((key) => (
                                    <React.Fragment key={key}>
                                        <p className="2xl:text-m text-sm font-medium leading-none">
                                            {key.charAt(0).toUpperCase() + key.slice(1)}
                                        </p>
                                        <DropdownMenu>
                                            <div>
                                                <DropdownMenuTrigger asChild disabled={!!params[key]}>
                                                    <Button
                                                        variant="outline"
                                                        className={`2xl:text-lg mt-2 mb-4 w-full ${params[key]
                                                            ? 'hidden'
                                                            : 'text-blue-600 hover:text-blue-800 cursor-pointer'
                                                            } ${opties[key]?.length < 2
                                                                ? 'text-gray-600 cursor-default bg-gray-200 hover:bg-gray-200 hover:text-gray-600'
                                                                : ''
                                                            }`}
                                                    >
                                                        {renderButtonText(params[key], opties[key])}
                                                    </Button>
                                                </DropdownMenuTrigger>
                                                <Button
                                                    variant="outline"
                                                    className={`mt-2 mb-4 w-full 2xl:text-lg ${params[key] ? 'block text-green-600 hover:text-red-600' : 'hidden'}`}
                                                    onClick={() => {
                                                        handleDropdownItemClick(key, null);
                                                    }}
                                                >
                                                    {renderButtonText(params[key], opties[key])}
                                                </Button>
                                            </div>
                                            <DropdownMenuContent
                                                className={`w-[calc(100vw-3rem)] md:w-[calc(50vw-3rem)] lg:w-[calc(40vw-3rem)] xl:w-[calc(35vw-3rem)] max-h-[30vh] overflow-y-auto ${opties[key]?.length < 2 || params[key] ? 'hidden' : ''}`}
                                            >
                                                <DropdownMenuGroup>
                                                    {!params[key] && opties[key]?.length !== 1 && opties[key]?.map((optie) => (
                                                        <DropdownMenuItem
                                                            key={optie}
                                                            className="cursor-pointer 2xl:text-lg"
                                                            onClick={() => handleDropdownItemClick(key, optie)}
                                                        >
                                                            {capitalizeFirstLetter(String(optie))}
                                                        </DropdownMenuItem>
                                                    ))}
                                                </DropdownMenuGroup>
                                            </DropdownMenuContent>
                                        </DropdownMenu>
                                    </React.Fragment>
                                ))}
                                <Button
                                    variant="link"
                                    className="h-[30px] 2xl:text-lg -mt-12 mb-6"
                                    onClick={async () => {
                                        const newParams = { ...params }; // Reset each key so that rerender is triggered
                                        Object.keys(newParams).forEach(key => { newParams[key] = null });
                                        setParams(newParams);
                                        setDate("");
                                        window.history.pushState({}, '', window.location.origin); // Reset URL
                                    }}
                                >
                                    <RotateCcw className="w-[18px] h-[18px] mr-1" />
                                    Voorkeuren resetten
                                </Button>
                            </TabsContent>
                            {showTab && ['Maken', 'Nakijken', 'Nagekeken'].map((status) => (
                                <TabsContent
                                    key={status}
                                    value={status}
                                    className="flex flex-col justify-center mt-0"
                                >
                                    {examens.filter(examen => examen.status === status && examen.switch === "on").length !== 0 && status !== 'Maken' ? (
                                        changeBox && naam ? (
                                            <p
                                                className="font-medium italic underline cursor-pointer -mt-2 mb-2"
                                                onClick={() => {
                                                    changeOpgaves.forEach(opgave => {
                                                        opgave.status = "Maken";
                                                    });
                                                    setExamens(examens);
                                                    fetch('/api/verwijder', {
                                                        method: 'POST',
                                                        body: JSON.stringify({ examens: changeOpgaves }),
                                                        headers: { 'Content-Type': 'application/json' }
                                                    });
                                                    setChangeOpgaves([]);
                                                    setChangeBox(false);
                                                }}
                                            >
                                                Terugzetten
                                            </p>
                                        ) : (
                                            <p
                                                className="font-medium italic underline cursor-pointer -mt-2 mb-2"
                                                onClick={() => {
                                                    setChangeBox(true);
                                                    setChangeOpgaves([]);
                                                }}
                                            >
                                                Selecteren
                                            </p>
                                        )
                                    ) : null}
                                    {examens &&
                                        examens
                                            .filter((examen) => examen.status === status && examen.switch === "on")
                                            .map((examen, index) => (
                                                <div key={examen.id} className="flex items-center gap-3">
                                                    {changeBox && (
                                                        <Checkbox
                                                            className={`${index === 0 ? 'mt-0' : 'mt-4'}`}
                                                            onClick={() => setChangeOpgaves((prev) => { return prev.includes(examen) ? prev.filter((opgave) => opgave !== examen) : [...prev, examen] })}
                                                        />
                                                    )}
                                                    <div
                                                        onClick={() => {
                                                            if (timeoutId) {
                                                                clearTimeout(timeoutId); // Clear the previous timeout
                                                            }
                                                            if (window.innerWidth < 640) {
                                                                setShowTab(false);
                                                            }
                                                            if (examen.id !== gekozenOpgave.id) {
                                                                setOpgave(examen);
                                                                setOpgaveKey('opg1');
                                                                if (naam && examen.status === "Maken") {
                                                                    const newTimeoutId = setTimeout(() => {
                                                                        let tempExamens = [...examens];
                                                                        let tempIndex = tempExamens.findIndex((tempExamen) => tempExamen.id === examen.id);
                                                                        tempExamens[tempIndex] = { ...tempExamens[tempIndex], status: "Nakijken" };
                                                                        setExamens(tempExamens);

                                                                        let sendParams = createSendParms(alleKeys, opties);
                                                                        fetch('/api/setscore', {
                                                                            method: 'POST',
                                                                            body: JSON.stringify({ score: null, examenId: examen.id, status: "Nakijken", sendParams: sendParams, link: window.location.href }),
                                                                            headers: { 'Content-Type': 'application/json' }
                                                                        });
                                                                        return examen.id;
                                                                    }, 1000 * 60);
                                                                    setTimeoutId(newTimeoutId);
                                                                    setVulScore(false);
                                                                }
                                                                let updateUrl = new URL(window.location.href);
                                                                updateUrl.searchParams.set("opgave", examen.id);
                                                                window.history.pushState({}, '', updateUrl);
                                                            }
                                                        }}
                                                        className={`cursor-pointer w-full flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-gray-50 ${examen.id === gekozenOpgave.id ? 'bg-gray-50' : 'bg-white'
                                                            } ${index === 0 ? 'mt-0' : 'mt-4'}`}
                                                    >
                                                        <div className="flex w-full flex-col gap-1">
                                                            <div className="flex items-center">
                                                                <div className="flex items-center gap-2">
                                                                    <div className="font-semibold 2xl:text-xl">
                                                                        {examen.titel
                                                                            .replaceAll('@@', ' ')
                                                                            .replaceAll('QQ', '?')}
                                                                    </div>
                                                                    <span
                                                                        className={`flex h-2 w-2 rounded-full 2xl:text-base ${status !== 'Maken' ? 'hidden' : ''}
                                             ${examen.moeilijkheidsgraad === 'makkelijk'
                                                                                ? 'bg-green-500'
                                                                                : examen.moeilijkheidsgraad === 'gemiddeld'
                                                                                    ? 'bg-yellow-500'
                                                                                    : examen.moeilijkheidsgraad
                                                                                        ? 'bg-red-500'
                                                                                        : ''
                                                                            }`}
                                                                    ></span>
                                                                </div>
                                                                <div className={`ml-auto text-xs text-muted-foreground 2xl:text-lg`}>
                                                                    {(() => {
                                                                        if (status === "Maken") {
                                                                            return examen.procent
                                                                                ? `Gem ${parseInt(examen.procent)}% vd ${examen.max}p`
                                                                                : `${examen.max}p`;
                                                                        } else if (status === "Nakijken") {
                                                                            return `Tijdvak ${examen.tijdvak} - ${examen.max}p`;
                                                                        } else if (status === "Nagekeken") {
                                                                            return `${examen.score}/${examen.max}p`;
                                                                        }
                                                                    })()}
                                                                </div>
                                                            </div>
                                                            <div className={`text-xs font-medium 2xl:text-lg ${status !== 'Maken' ? 'hidden' : ''}`}>
                                                                {examen.niveau.charAt(0).toUpperCase() +
                                                                    examen.niveau.slice(1)}
                                                                , {examen.jaar}-{examen.tijdvak}
                                                            </div>
                                                        </div>
                                                        {examen.onderwerp && (
                                                            <div className={`line-clamp-2 text-xs text-muted-foreground 2xl:text-lg ${status !== 'Maken' ? 'hidden' : ''}`}>
                                                                {examen.onderwerp.replaceAll('#', ', ')}
                                                            </div>
                                                        )}
                                                        {vulScore && gekozenOpgave.id === examen.id && status !== "Nagekeken" ? (
                                                            <>
                                                                <div id="border" className="w-full border-t" />
                                                                <form onSubmit={async (e) => {
                                                                    e.preventDefault();
                                                                    const formData = new FormData(e.target);
                                                                    const score = parseInt(formData.get('score'));
                                                                    if (score && !String(score).includes('.') && !String(score).includes(',')) {
                                                                        if (-1 < score && score < (examen.max + 1)) {
                                                                            examen.score = score;
                                                                            examen.status = "Nagekeken";
                                                                            let sendParams = createSendParms(alleKeys, opties);
                                                                            await fetch('/api/setscore', { method: 'POST', body: JSON.stringify({ score: score, examenId: examen.id, status: examen.status, sendParams: sendParams, link: window.location.href }), headers: { 'Content-Type': 'application/json' } })
                                                                            setVulScore(false);

                                                                            if (!examen.gem) {
                                                                                examen.gem = score;
                                                                            }
                                                                        }
                                                                    }
                                                                }} className="flex w-full items-center space-x-2">
                                                                    <Input name="score" type="integer" placeholder={`Score/${examen.max}p`} onClick={(e) => e.preventDefault()} />
                                                                    <Button type="submit">
                                                                        <ArrowRightIcon className="ml-1 size-3 transition-transform duration-300 ease-in-out group-hover:translate-x-0.5" />
                                                                    </Button>
                                                                </form>
                                                            </>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            ))}
                                </TabsContent>

                            ))}
                        </Tabs>
                    </div >
                )
                }
                <div id="midden" className={`sm:h-[100vh] sm:overflow-y-auto ${sidebarOpen ? 'w-[100vw] md:w-[50vw] lg:w-[60vw] xl:w-[65vw]' : 'w-[100vw] py-10 md:pl-14'}`}>
                    {gekozenOpgave && opgaveKey && gekozenOpgave[opgaveKey] ? (
                        <div className="w-full h-full">
                            <div>
                                {opgaveKey === "videos" ? (
                                    gekozenOpgave[opgaveKey].split("~").map((src, index) => (
                                        <div key={index} className="sm:w-[calc(100% - 80px + 2rem)] 2xl:w-[calc(100% - 140px + 2rem)]">
                                            <iframe
                                                src={`https://www.youtube.com/embed/${src}`}
                                                title={`Video ${index}`}
                                                allowFullScreen
                                                className="w-full aspect-video"
                                                alt={`Uitlegvideos over examenopgave genaamd ${gekozenOpgave.titel.replaceAll('@@', ' ')}`}
                                            />
                                        </div>
                                    ))
                                ) : (
                                    gekozenOpgave[opgaveKey].split("#").map((src, index) => (
                                        <div
                                            key={index}
                                            className={`w-full overflow-x-hidden ${sidebarOpen ? 'lg:pl-[8%]' : ''} py-[8.5%]`}
                                        >
                                            <img
                                                src={`/splitImages/${src}`}
                                                alt={`Examenopgave genaamd ${gekozenOpgave.titel.replaceAll('@@', ' ')}`}
                                                className="w-full transform scale-[1.15]"
                                            />
                                        </div>
                                    ))
                                )}
                            </div>
                            <div
                                id="linkerbalk"
                                className={`bg-transparent max-h-[calc(100vh-1rem)] overflow-y-auto fixed flex flex-col sm:flex-row justify-center w-full sm:w-[80px] 2xl:w-[140px] bottom-2 sm:top-2 ${sidebarOpen
                                    ? 'left-0 md:left-[calc(50vw+1rem)] lg:left-[calc(40vw+1rem)] xl:left-[calc(35vw+1rem)]'
                                    : 'left-4'
                                    }`}
                            >
                                <div className="w-full flex flex-wrap justify-center sm:justify-start sm:block sm:w-[62px]">
                                    <fieldset className="flex flex-row sm:w-[62px] 2xl:w-[80px] bg-white sm:grid gap-1 rounded-lg border p-1 pt-0 mt-2">
                                        <legend className="-ml-1 px-1 text-sm font-medium 2xl:text-lg">Menu</legend>
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <div
                                                        className={`w-auto h-auto sm:h-full sm:w-full flex py-2 px-[0.875rem] items-center justify-center cursor-pointer rounded-lg text-gray-600 hover:text-black`}
                                                        onClick={() => setSidebarOpen(!sidebarOpen)}
                                                    >
                                                        {sidebarOpen ? <X className="2xl:w-9 2xl:h-9" /> : <Menu className="2xl:w-9 2xl:h-9" />}
                                                    </div>
                                                </TooltipTrigger>
                                                <TooltipContent side="right" className="ml-1 2xl:text-base">
                                                    Linkermenu
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>

                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <div
                                                        className="w-auto h-auto sm:h-full sm:w-full flex py-2 px-[0.875rem] items-center justify-center cursor-pointer rounded-lg text-gray-600 hover:text-black"
                                                        onClick={() =>
                                                            window.open(
                                                                'https://docs.google.com/forms/d/e/1FAIpQLSdcjHFeUCDMkmuGe1X77XeVlbWy57Jgbm__ilZ5EuEE77dNRQ/viewform'
                                                            )
                                                        }
                                                    >
                                                        <Megaphone className="2xl:w-9 2xl:h-9" />
                                                    </div>
                                                </TooltipTrigger>
                                                <TooltipContent side="right" className="ml-1 2xl:text-base">
                                                    Feedback
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>

                                        {gekozenOpgave['link'] && (
                                            <TooltipProvider>
                                                <Tooltip>
                                                    <TooltipTrigger asChild>
                                                        <div
                                                            className="w-auto h-auto sm:h-full sm:w-full flex py-2 px-[0.875rem] items-center justify-center cursor-pointer rounded-lg text-gray-600 hover:text-black"
                                                            onClick={() => window.open(gekozenOpgave['link'])}
                                                        >
                                                            <SquareArrowOutUpRight className="2xl:w-9 2xl:h-9" />
                                                        </div>
                                                    </TooltipTrigger>
                                                    <TooltipContent side="right" className="ml-1 2xl:text-base">
                                                        Examenblad
                                                    </TooltipContent>
                                                </Tooltip>
                                            </TooltipProvider>
                                        )}
                                    </fieldset>
                                    {Object.keys(icons).map(
                                        (typeKey) =>
                                            Object.keys(icons[typeKey]).some((iconKey) => gekozenOpgave[iconKey]) && (
                                                <fieldset className="justify-center flex flex-row sm:w-[62px] bg-white sm:grid gap-1 rounded-lg border p-1 pt-0 mt-2 ml-2 sm:ml-0">
                                                    <legend className="-ml-1 px-1 text-sm font-medium 2xl:text-lg">{typeKey}</legend>
                                                    {Object.keys(icons[typeKey]).map(
                                                        (iconKey) =>
                                                            gekozenOpgave[iconKey] && (
                                                                <div key={iconKey}>
                                                                    <TooltipProvider>
                                                                        <Tooltip>
                                                                            <TooltipTrigger asChild>
                                                                                <div
                                                                                    className={`w-auto sm:w-full h-auto sm:h-full flex py-2 px-[0.875rem] items-center justify-center cursor-pointer rounded-lg ${iconKey === opgaveKey
                                                                                        ? 'bg-gray-100 text-black'
                                                                                        : 'text-gray-600 hover:text-black'
                                                                                        }`}
                                                                                    onClick={() => {
                                                                                        setOpgaveKey(iconKey);
                                                                                        if (iconKey === 'cv1' && naam) {
                                                                                            setVulScore(true);
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    {icons[typeKey][iconKey]}
                                                                                </div>
                                                                            </TooltipTrigger>
                                                                            <TooltipContent className="ml-1" side="right">
                                                                                {iconKey.includes('opg')
                                                                                    ? 'Opgave'
                                                                                    : iconKey.includes('bij')
                                                                                        ? 'Bijlage'
                                                                                        : iconKey.includes('uit')
                                                                                            ? 'Uitwerkbijlage'
                                                                                            : iconKey.includes('cv')
                                                                                                ? 'Beoordeling'
                                                                                                : iconKey.includes('kk')
                                                                                                    ? 'Kaartenkatern'
                                                                                                    : iconKey.includes('k')
                                                                                                        ? 'Kleurenbijlage'
                                                                                                        : iconKey.includes('videos')
                                                                                                            ? 'Uitlegvideos'
                                                                                                            : ''}
                                                                            </TooltipContent>
                                                                        </Tooltip>
                                                                    </TooltipProvider>
                                                                </div>
                                                            )
                                                    )}
                                                </fieldset>
                                            )
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div id="intro">
                            <div className="p-6 sm:p-8 sm:pt-36 2xl:pt-38 2xl:p-12">
                                <h2 className="scroll-m-20 text-3xl font-semibold tracking-tight first:mt-0 2xl:text-4xl">{naam ? `👋 Hoi ${naam.split(" ")[0]},` : `🔴 Eindexamen.space`}</h2>
                                <p className="text-xl text-muted-foreground pb-2 2xl:text-3xl">Lanceer je cijfers de hoogte in!</p>
                                <div className="w-[165px] 2xl:w-[195px] 2xl:pb-24 pb-20">
                                    <a href="https://lewan.design" target="_blank">
                                        <div className={cn("group rounded-full border border-black/5 bg-neutral-100 text-base text-white transition-all ease-in hover:cursor-pointer hover:bg-neutral-200 dark:border-white/5 dark:bg-neutral-900 dark:hover:bg-neutral-800",)}>
                                            <TextShimmer className="2xl:text-xl inline-flex items-center justify-center px-4 py-1 transition ease-out hover:text-neutral-600 hover:duration-300 hover:dark:text-neutral-400">
                                                <span>✨ Door Lewan</span>
                                                <ArrowRightIcon className="ml-1 size-3 transition-transform duration-300 ease-in-out group-hover:translate-x-0.5" />
                                            </TextShimmer>
                                        </div>
                                    </a>
                                </div>
                                {naam && !startPagina ? (
                                    <Tabs value={activeTab} onValueChange={setTab}>
                                        <div className="flex">
                                            <TabsList>
                                                <TabsTrigger value="lijst">Lijst</TabsTrigger>
                                                <TabsTrigger value="grafiek"
                                                    disabled={sessies.filter(sessie => sessie.switch === "on" && sessie.nakijken === 0).length === 0}
                                                >Statistieken</TabsTrigger>
                                            </TabsList>
                                            <DropdownMenu>
                                                <DropdownMenuTrigger>
                                                    <div className={`flex items-center justify-center cursor-pointer rounded-lg  text-gray-600 hover:text-black py-2 px-1`}>
                                                        <EllipsisVertical className="2xl:w-9 2xl:h-9" />
                                                    </div>
                                                </DropdownMenuTrigger>
                                                <DropdownMenuContent align="start" className="-ml-1">
                                                    <DropdownMenuItem className="cursor-pointer" onClick={() => setPagina(true)}><Wrench className="mr-2 h-4 w-4" /><span> Startpagina</span></DropdownMenuItem>
                                                    <DropdownMenuItem className="cursor-pointer" onClick={() => window.location.href = "/uitloggen"}><LogOut className="mr-2 h-4 w-4" /><span> Uitloggen</span></DropdownMenuItem>
                                                    <DropdownMenuItem className="cursor-pointer" onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSdcjHFeUCDMkmuGe1X77XeVlbWy57Jgbm__ilZ5EuEE77dNRQ/viewform")}><Megaphone className="mr-2 h-4 w-4" /><span> Feedback</span></DropdownMenuItem>
                                                </DropdownMenuContent>
                                            </DropdownMenu>
                                        </div>
                                        <TabsContent value="lijst">
                                            <Card x-chunk="dashboard-05-chunk-3">
                                                <CardHeader className="px-7">
                                                    <CardTitle>Lijst 📅</CardTitle>
                                                    <CardDescription>
                                                        Vind hier een lijst van al je studiesessies
                                                    </CardDescription>
                                                </CardHeader>
                                                <CardContent>
                                                    <Table>
                                                        <TableHeader>
                                                            <TableRow className="hover:bg-white">
                                                                {['Datum', 'Jouw score vs gem', 'Vak, onderwerp', 'Jaar - tijdvak', 'Link'].map((header, index) => (
                                                                    <TableHead key={index}>{header}</TableHead>
                                                                ))}
                                                            </TableRow>
                                                        </TableHeader>
                                                        <TableBody>
                                                            {sessies.filter(sessie => sessie.switch === "on").length > 0 ? (
                                                                sessies.filter(sessie => sessie.switch === "on").map((sessie) => (
                                                                    <React.Fragment key={sessie.sessieId}>
                                                                        <TableRow
                                                                            className={`cursor-pointer ${openSessies.includes(sessie.sessieId) ? "border-top-slate-400 box-border" : undefined}`}
                                                                            data-state={openSessies.includes(sessie.sessieId) ? "selected" : undefined}
                                                                        >
                                                                            <TableCell
                                                                                className="min-w-[150px] p-2 md:p-4"
                                                                                onClick={() =>
                                                                                    setOpenSessies(
                                                                                        openSessies.includes(sessie.sessieId)
                                                                                            ? openSessies.filter((id) => id !== sessie.sessieId)
                                                                                            : [...openSessies, sessie.sessieId]
                                                                                    )
                                                                                }>
                                                                                {sessie.datum.charAt(0).toUpperCase() + sessie.datum.slice(1)}
                                                                            </TableCell>
                                                                            <TableCell
                                                                                className="min-w-[150px] p-2 md:p-4"
                                                                                onClick={() =>
                                                                                    setOpenSessies(
                                                                                        openSessies.includes(sessie.sessieId)
                                                                                            ? openSessies.filter((id) => id !== sessie.sessieId)
                                                                                            : [...openSessies, sessie.sessieId]
                                                                                    )
                                                                                }>
                                                                                {(() => {
                                                                                    if (sessie.nakijken !== 0) {
                                                                                        return (
                                                                                            <div className="flex items-center">
                                                                                                <div className="flex h-2 w-2 rounded-full bg-blue-500 mr-1" />
                                                                                                <span>{`Nog ${sessie.nakijken}p nakijken`}</span>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                    let circleColor;
                                                                                    if (!sessie.gem) {
                                                                                        circleColor = "bg-orange-500";
                                                                                    } else if (sessie.score > sessie.gem) {
                                                                                        circleColor = "bg-green-500";
                                                                                    } else {
                                                                                        circleColor = "bg-red-500";
                                                                                    }

                                                                                    return (
                                                                                        <div className="flex items-center">
                                                                                            <div className={`mr-1 flex h-2 w-2 rounded-full ${circleColor}`} />
                                                                                            <span>{` ${sessie.score}p vs ${sessie.gem || "0"}p (${sessie.max}p)`}</span>
                                                                                        </div>
                                                                                    );
                                                                                })()}
                                                                            </TableCell>
                                                                            <TableCell
                                                                                className="min-w-[150px] p-2 md:p-4"
                                                                                onClick={() =>
                                                                                    setOpenSessies(
                                                                                        openSessies.includes(sessie.sessieId)
                                                                                            ? openSessies.filter((id) => id !== sessie.sessieId)
                                                                                            : [...openSessies, sessie.sessieId]
                                                                                    )
                                                                                }>
                                                                                {sessie.vak.charAt(0).toUpperCase() + sessie.vak.slice(1)}
                                                                                {sessie.onderwerp && `: ${sessie.onderwerp.toLowerCase()}`}
                                                                            </TableCell>
                                                                            <TableCell
                                                                                className="p-2 md:p-4"
                                                                                onClick={() =>
                                                                                    setOpenSessies(
                                                                                        openSessies.includes(sessie.sessieId)
                                                                                            ? openSessies.filter((id) => id !== sessie.sessieId)
                                                                                            : [...openSessies, sessie.sessieId]
                                                                                    )
                                                                                }>
                                                                                {sessie.jaar && sessie.tijdvak ? `${sessie.jaar} - ${sessie.tijdvak}` : `${sessie.jaar}${sessie.tijdvak}`}
                                                                            </TableCell>
                                                                            <TableCell className="p-2 md:p-4 text-gray-600 hover:text-black items-center"><SquareArrowOutUpRight className="ml-2 h-[18px] w-[18px] 2xl:w-[28px] 2xl:h-[28px]" onClick={() => {
                                                                                if (window.location.href != sessie.link) {
                                                                                    setParams({ 'vak': sessie.vak, 'onderwerp': sessie.onderwerp, 'jaar': String(sessie.jaar), 'tijdvak': String(sessie.tijdvak) });
                                                                                    window.history.pushState({}, '', sessie.link);
                                                                                }
                                                                            }
                                                                            } /></TableCell>
                                                                        </TableRow>

                                                                        {openSessies.includes(sessie.sessieId) && (
                                                                            <>
                                                                                <TableRow className="hover:bg-white text-[#64748B] font-medium">
                                                                                    <TableCell colSpan={1} className="flex"><CornerDownRight className="-mt-1 w-[22px] h-[22px]" />&nbsp;&nbsp;&nbsp;Titel</TableCell>
                                                                                    <TableCell colSpan={4} >Jouw score vs gem</TableCell>
                                                                                </TableRow>
                                                                                {sessie.opgaves.map((opg, index) => (
                                                                                    <TableRow
                                                                                        className={`cursor-pointer ${index === sessies.filter(sessies => sessie.switch === "on").length - 1 ? "border-t-slate-400 box-border" : undefined}`}
                                                                                        key={`${sessie.sessieId}`}
                                                                                        onClick={() => {
                                                                                            if (window.innerWidth < 640) {
                                                                                                setShowTab(false);
                                                                                            }
                                                                                            setOpgave(examens.find(examen => examen.id === opg.id))
                                                                                        }}
                                                                                    >
                                                                                        <TableCell colSpan={1}>
                                                                                            {opg.titel.replaceAll("@@", " ")}
                                                                                        </TableCell>
                                                                                        <TableCell
                                                                                            colSpan={4}>
                                                                                            {(() => {
                                                                                                if (!opg.score) {
                                                                                                    return (
                                                                                                        <div className="flex items-center">
                                                                                                            <div className="flex h-2 w-2 rounded-full bg-blue-500 mr-1" />
                                                                                                            <span>{`Nog ${opg.max}p nakijken`}</span>
                                                                                                        </div>
                                                                                                    );
                                                                                                }

                                                                                                let circleColor;
                                                                                                if (!opg.gem) {
                                                                                                    circleColor = "bg-orange-500";
                                                                                                } else if (parseInt(opg.score) > parseInt(opg.gem)) {
                                                                                                    circleColor = "bg-green-500";
                                                                                                } else {
                                                                                                    circleColor = "bg-red-500";
                                                                                                }
                                                                                                return (
                                                                                                    <div className="flex items-center">
                                                                                                        <div className={`mr-1 flex h-2 w-2 rounded-full ${circleColor}`} />
                                                                                                        <span>{opg.score}p vs {opg.gem || 0}p ({opg.max}p)</span>
                                                                                                    </div>
                                                                                                );
                                                                                            })()}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                ))}
                                                                            </>
                                                                        )}
                                                                    </React.Fragment>
                                                                ))
                                                            ) : (
                                                                <TableRow className="hover:bg-white">
                                                                    <TableCell colSpan={5} className="h-24 text-center">
                                                                        Nog geen opgaves gemaakt...
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </CardContent>
                                            </Card>
                                        </TabsContent>
                                        <TabsContent value="grafiek">
                                            <Card x-chunk="dashboard-05-chunk-3">
                                                <CardHeader className="px-7">
                                                    <CardTitle>Statistieken 📈</CardTitle>
                                                    <CardDescription>
                                                        Zie hier al je scores door de tijd heen
                                                    </CardDescription>
                                                </CardHeader>
                                                <CardContent className="pb-0">
                                                    <div id="chart">
                                                        {sessies.filter(sessie => sessie.switch === "on").length > 0 ? (
                                                            <ApexChart sessies={sessies} setTab={setTab} setSessies={setSessies} />
                                                        ) : (
                                                            null
                                                        )}
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </TabsContent>
                                    </Tabs>
                                ) : (
                                    <div>
                                        <Card x-chunk="dashboard-04-chunk-1">
                                            <CardHeader>
                                                <CardTitle className="2xl:text-3xl">Hoe werkt het? 🛠</CardTitle>
                                                <CardDescription className="2xl:text-2xl" >
                                                    Lees hier hoe de website in elkaar zit!
                                                </CardDescription>
                                            </CardHeader>
                                            <CardContent>
                                                <div className="grid grid-cols-[25px_1fr] items-start pb-4 last:mb-0 last:pb-0">
                                                    <span className="flex h-2 w-2 translate-y-1 rounded-full bg-sky-500" />
                                                    <div className="space-y-1">
                                                        <p className="color-black text-sm font-medium leading-none 2xl:text-xl">
                                                            Wat wil je leren? 🤔
                                                        </p>
                                                        <p className="text-sm text-muted-foreground 2xl:text-lg">
                                                            Links onder het tabje met het tandwiel, kun je jouw voorkeuren doorgeven
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-[25px_1fr] items-start pb-4 last:mb-0 last:pb-0">
                                                    <span className="flex h-2 w-2 translate-y-1 rounded-full bg-sky-500" />
                                                    <div className="space-y-1">
                                                        <p className="color-black text-sm font-medium leading-none 2xl:text-xl">
                                                            Opgaves zoeken 🔍
                                                        </p>
                                                        <p className="text-sm text-muted-foreground 2xl:text-lg">
                                                            Onder 'Maken' vind je alle relevante opgaves
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-[25px_1fr] items-start pb-4 last:mb-0 last:pb-0">
                                                    <span className="flex h-2 w-2 translate-y-1 rounded-full bg-sky-500" />
                                                    <div className="space-y-1">
                                                        <p className="color-black text-sm font-medium leading-none 2xl:text-xl">
                                                            Maken 🖊️
                                                        </p>
                                                        <p className="text-sm text-muted-foreground 2xl:text-lg">
                                                            In de opgave, vind je bij 'Maken' de opgave, (uitwerk)bijlage, kaartenkatern en kleurenbijlage
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-[25px_1fr] items-start pb-4 last:mb-0 last:pb-0">
                                                    <span className="flex h-2 w-2 translate-y-1 rounded-full bg-sky-500" />
                                                    <div className="space-y-1">
                                                        <p className="color-black text-sm font-medium leading-none 2xl:text-xl">
                                                            Nakijken ✅
                                                        </p>
                                                        <p className="text-sm text-muted-foreground 2xl:text-lg">
                                                            Als je met een opgave klaar bent, kun je onder 'Nakijken' de correctie en uitlegvideo's vinden
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-[25px_1fr] items-start pb-4 last:mb-0 last:pb-0">
                                                    <span className="flex h-2 w-2 translate-y-1 rounded-full bg-sky-500" />
                                                    <div className="space-y-1">
                                                        <p className="color-black text-sm font-medium leading-none 2xl:text-xl">
                                                            Errata 🦄
                                                        </p>
                                                        <p className="text-sm text-muted-foreground 2xl:text-lg">
                                                            Als er aanvullingen op een opgave zijn, kun je die onder 'Errata' vinden
                                                        </p>
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </Card>

                                        <Card x-chunk="dashboard-04-chunk-1" className="mt-6 sm:mt-8">
                                            <CardHeader>
                                                <CardTitle className="2xl:text-3xl">Google koppelen 💫</CardTitle>
                                                <CardDescription className="2xl:text-2xl" >
                                                    Unlock extra features door je Google account te koppelen!
                                                </CardDescription>
                                            </CardHeader>
                                            <CardContent className="2xl:text-xl">
                                                Nadat je Google koppelt, komen er onder "... Opgaves" 3 tabjes:
                                                <div className="mt-3 grid grid-cols-[25px_1fr] items-start pb-4 last:mb-0 last:pb-0">
                                                    <span className="flex h-2 w-2 translate-y-1 rounded-full bg-red-500" />
                                                    <div className="space-y-1">
                                                        <p className="color-black text-sm font-medium leading-none 2xl:text-xl">
                                                            Sorteren op ongemaakte opgaves 💯
                                                        </p>
                                                        <p className="text-sm text-muted-foreground 2xl:text-lg">
                                                            Onder het tabje 'Maken' vind je alle opgaves die je nog niet gemaakt hebt
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-[25px_1fr] items-start pb-4 last:mb-0 last:pb-4">
                                                    <span className="flex h-2 w-2 translate-y-1 rounded-full bg-red-500" />
                                                    <div className="space-y-1">
                                                        <p className="color-black text-sm font-medium leading-none 2xl:text-xl">
                                                            Meerdere opgaves maken en daarna nakijken 💨
                                                        </p>
                                                        <p className="text-sm text-muted-foreground 2xl:text-lg">
                                                            Bij 'Nakijken', vind je alle opgaves die je voor langer dan 1 minuut hebt bezocht, maar nog hebt niet nagekeken
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-[25px_1fr] items-start pb-4 last:mb-0 last:pb-0">
                                                    <span className="flex h-2 w-2 translate-y-1 rounded-full bg-red-500" />
                                                    <div className="space-y-1">
                                                        <p className="color-black text-sm font-medium leading-none 2xl:text-xl">
                                                            Oude scores terugkijken 👀
                                                        </p>
                                                        <p className="text-sm text-muted-foreground 2xl:text-lg">
                                                            Onder 'Nagekeken', kun je al je scores terugzien. Bij het openen van Eindexamen.space kun je al jouw leersessies vinden gesorteerd op datum en voorkeuren
                                                        </p>
                                                    </div>
                                                </div>
                                                Door Google te koppelen, ga je akkoord met onze <a className="bold underline hover:italic font-medium" target="_blank" href="/servicevoorwaarden">servicevoorwaarden</a> en <a className="bold underline hover:italic font-medium" target="_blank" href="/privacybeleid">privacybeleid</a>. Van je Google account onthouden we je naam, emailadres en google id.
                                                <div className="w-full h-3"></div>
                                                Voor vragen kun je altijd mailen naar <a className="bold underline hover:italic font-medium" href="mailto:l.d.paalvast@gmail.com">l.d.paalvast@gmail.com</a>.
                                            </CardContent>
                                            <CardFooter className="-mt-2 border-t px-6 py-4">
                                                {startPagina ? (
                                                    <div className="flex">
                                                        <Button
                                                            variant="link"
                                                            onClick={() => setPagina(false)}
                                                            className="2xl:text-lg"
                                                        >
                                                            <Undo2 className="w-[22px] h-[22px] mr-1" />Terug naar ingelogd
                                                        </Button>
                                                    </div>
                                                ) : (
                                                    <GoogleLogin
                                                        clientId="912815851948-ourmn2iuliakso9ud5oolb03fk1smsqd.apps.googleusercontent.com"
                                                        buttonText="Login with Google"
                                                        onSuccess={onSuccess}
                                                        locale="nl"
                                                        cookiePolicy={'single_host_origin'}
                                                    />
                                                )}
                                            </CardFooter>
                                        </Card>
                                        <Card x-chunk="dashboard-04-chunk-1" className="mt-6 sm:mt-8">
                                            <CardHeader>
                                                <CardTitle className="2xl:text-3xl">Eindexamen.space verbeteren 🙌</CardTitle>
                                                <CardDescription className="2xl:text-2xl" >
                                                    Help mee en je naam komt op de site te staan!
                                                </CardDescription>
                                            </CardHeader>
                                            <CardContent>
                                                Dingen waarbij je kunt helpen:
                                                <div className="mt-3 grid grid-cols-[25px_1fr] items-start pb-4 last:mb-0 last:pb-0">
                                                    <span className="flex h-2 w-2 translate-y-1 rounded-full bg-amber-500" />
                                                    <div className="space-y-1">
                                                        <p className="color-black text-sm font-medium leading-none 2xl:text-xl">
                                                            Het onderwerpen algoritme is nog niet perfect 🤏
                                                        </p>
                                                        <p className="text-sm text-muted-foreground 2xl:text-lg">
                                                            Hier is het document met onze database en instructies: <a className="text-blue-600 italic underline" target="__blank__" href="https://docs.google.com/document/d/1jU2oceOz1rw2I0r8vfhEMaquwbVjsoX8uAt3PNRmKAE/edit?usp=sharing">Google doc</a>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="mt-3 grid grid-cols-[25px_1fr] items-start pb-4 last:mb-0 last:pb-0">
                                                    <span className="flex h-2 w-2 translate-y-1 rounded-full bg-amber-500" />
                                                    <div className="space-y-1">
                                                        <p className="color-black text-sm font-medium leading-none 2xl:text-xl">
                                                            Voeg YT kanalen toe aan onze censuur voor de uitlegvideo's 📼
                                                        </p>
                                                        <p className="text-sm text-muted-foreground 2xl:text-lg">
                                                            Leg uit waarom we een kanaal moeten toevoegen in deze <a className="text-blue-600 italic underline" target="__blank__" href="https://docs.google.com/forms/d/e/1FAIpQLSdcjHFeUCDMkmuGe1X77XeVlbWy57Jgbm__ilZ5EuEE77dNRQ/viewform">Google form</a>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-[25px_1fr] items-start pb-4 last:mb-0 last:pb-0">
                                                    <span className="flex h-2 w-2 translate-y-1 rounded-full bg-amber-500" />
                                                    <div className="space-y-1">
                                                        <p className="color-black text-sm font-medium leading-none 2xl:text-xl">
                                                            Als je fouten/verbeteringen ziet, laat het ons weten! 💡
                                                        </p>
                                                        <p className="text-sm text-muted-foreground 2xl:text-lg">
                                                            Laat je mening horen dmv deze <a className="text-blue-600 italic underline" target="__blank__" href="https://docs.google.com/forms/d/e/1FAIpQLSdcjHFeUCDMkmuGe1X77XeVlbWy57Jgbm__ilZ5EuEE77dNRQ/viewform">Google form</a>
                                                        </p>
                                                    </div>
                                                </div>
                                                Mensen die al hebben geholpen:
                                                <div className="mt-3 grid grid-cols-[25px_1fr] items-start pb-4 last:mb-0 last:pb-0">
                                                    <span className="flex h-2 w-2 translate-y-1 rounded-full bg-violet-500" />
                                                    <div className="space-y-1">
                                                        <p className="color-black text-sm font-medium leading-none 2xl:text-xl">
                                                            Pelle Drijver, software engineer bij Google, Zwitzerland 💻
                                                        </p>
                                                        <p className="text-sm text-muted-foreground 2xl:text-lg">
                                                            Hulp en inzichten over manieren om het project te publiceren
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-[25px_1fr] items-start pb-4 last:mb-0 last:pb-0">
                                                    <span className="flex h-2 w-2 translate-y-1 rounded-full bg-violet-500" />
                                                    <div className="space-y-1">
                                                        <p className="color-black text-sm font-medium leading-none 2xl:text-xl">
                                                            Jiska Heringa, UX/UI designer bij Infinitas Holding B.V. 🎨
                                                        </p>
                                                        <p className="text-sm text-muted-foreground 2xl:text-lg">
                                                            Advies over belangrijke punten bij ontwerpen van een leerplatform
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-[25px_1fr] items-start pb-4 last:mb-0 last:pb-0">
                                                    <span className="flex h-2 w-2 translate-y-1 rounded-full bg-violet-500" />
                                                    <div className="space-y-1">
                                                        <p className="color-black text-sm font-medium leading-none 2xl:text-xl">
                                                            Wouter van Dam, student TU Delft, Computer Science & Engineering 🧑‍🎓
                                                        </p>
                                                        <p className="text-sm text-muted-foreground 2xl:text-lg">
                                                            Hulp bij opzetten van verbinding tussen server en Raspberry Pi
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-[25px_1fr] items-start pb-4 last:mb-0 last:pb-0">
                                                    <span className="flex h-2 w-2 translate-y-1 rounded-full bg-violet-500" />
                                                    <div className="space-y-1">
                                                        <p className="color-black text-sm font-medium leading-none 2xl:text-xl">
                                                            Frans van Haandel, eigenaar wiskunde-examens.nl 🌐
                                                        </p>
                                                        <p className="text-sm text-muted-foreground 2xl:text-lg">
                                                            Inzichten over het scrapen van de TIA scores van het Cito
                                                        </p>
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </div>
                                )}
                                <div>
                                    <p className="mt-2 ml-4">Copyright © 2024 - Nu | All rights reserved  | <a className="bold underline hover:italic font-medium" target="_blank" href="/servicevoorwaarden">Servicevoorwaarden</a> en <a target="_blank" className="font-medium bold underline hover:italic" href="/privacybeleid">privacybeleid</a></p>
                                </div>
                            </div>
                        </div >
                    )}
                    {eMsg && (<ErrPopup error={eMsg} />)}
                </div >
            </div >
        </>
    )
}

export default App;

import React from "react";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "../ui/alert-dialog";

const ErrPopup = ({ error }) => {
    return (
        <AlertDialog>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Oeps. Je bent tegen een error aangelopen...</AlertDialogTitle>
                    <AlertDialogDescription>
                        {error}<br /><br />*Je kunt ons hierover mailen op vragen@eindexamen.space of de pagina herladen.
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel onClick={() => window.location.reload()}>Herladen</AlertDialogCancel>
                    <a href="mailto:vragen@eindexamen.space">
                        <AlertDialogAction>Mailen</AlertDialogAction>
                    </a>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
}

export default ErrPopup;
